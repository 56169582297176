import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { FaCalendarAlt, FaClock, FaDollarSign, FaJava, FaMapMarkerAlt } from 'react-icons/fa';
import CourseTrait from '../../components/courses/CourseTrait';
import HeaderBar from '../../components/courses/HeaderBar';
import styles from '../../styles/courses/courseimage.module.css';
import withTranslations from '../../components/hoc/withTranslations';

const JavaFxCourse = () => (
  <Layout>
    <Seo
      title='Taller JavaFX'
      description={
        'Aprende JavaFX, una plataforma de desarrollo robusta para la creación de aplicaciones de escritorio eficientes que\n' +
        '            corren en Windows, macOS y Linux.'
      }
      keywords={[`javafx`, `ui`, `desktop`]}
    />

    <FaJava size='6em' />
    <br />
    <br />

    <h1>Taller de JavaFX</h1>

    <p>
      JavaFX es una plataforma de desarrollo robusta para la creación de aplicaciones de escritorio eficientes que
      corren en Windows, macOS y Linux.
    </p>
    <p>
      <b>Inicio: </b> Próximamente
    </p>

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 50
      }}
    >
      <CourseTrait icon={<FaCalendarAlt color='#FFA500' size='4em' />}>Lunes a Viernes 10-11</CourseTrait>
      <CourseTrait icon={<FaClock color='#FFA500' size='4em' />}>10 horas</CourseTrait>
      <CourseTrait icon={<FaMapMarkerAlt color='#FFA500' size='4em' />}>ITS</CourseTrait>
      <CourseTrait icon={<FaDollarSign color='#FFA500' size='4em' />}>150 MXN</CourseTrait>
    </div>

    <HeaderBar>Lo que aprenderás</HeaderBar>
    <p>
      Al finalizar el curso, podrás desarrollar un programa de escritorio que utilice los patrones de diseño más
      populares. Serás capaz de crear una interfaz gráfica moderna para que un usuario interactúe a través de botones,
      campos de texto, listas desplegables, tablas y más.
    </p>

    <p>
      Obtendrás la habilidad de mostrar información visualmente, haciendo uso de gráficas, colores, figuras geométricas
      y estilos personalizados.
    </p>

    <p>
      Aprenderás a trabajar con Maven, la herramienta de construcción más popular de Java que facilita el proceso de
      añadir dependencias y la generación de archivos ejecutables.
    </p>

    <p>
      Crearás un modelo de dominio y lo aplicarás en el ámbito práctico a diferentes <i>vistas</i> dentro de una
      aplicación.
    </p>

    <div className={styles.bigContainer}>
      <div className={styles.imageContainer}>
        <img
          src='https://github.com/FXyz/FXyz/raw/master/resources/fxsampler.png'
          alt=''
          className={styles.sampleImage}
        />
      </div>

      <div className={styles.imageContainer}>
        <img src='https://pbs.twimg.com/media/C4KEsctWcAQD_al.jpg' alt='' className={styles.sampleImage} />
      </div>
    </div>

    <HeaderBar>Contenido</HeaderBar>
    <p>
      <b>Módulo 1 </b>Introducción
    </p>
    <p>
      <b>Módulo 2 </b>Conceptos básicos
    </p>
    <p>
      <b>Módulo 3 </b>FXML
    </p>
    <p>
      <b>Módulo 4 </b>Controllers
    </p>
    <p>
      <b>Módulo 5 </b>Proyecto integrador
    </p>

    <HeaderBar>Requerimientos</HeaderBar>
    <ul>
      <li>Conocimientos básicos de Java</li>
      <li>Laptop</li>
    </ul>

    <HeaderBar>Instructores</HeaderBar>

    <p>
      <b>Alex Vilchis y Fernando Pinedo</b>
    </p>

    <p>
      Desarrolladores de <a href='https://haztuhorario.com'>haztuhorario.com</a>. Han estado involucrado en docenas de
      aplicaciones con JavaFX para fines educativos y comerciales. Tienen experiencia en la industria manufacturera,
      creando software especializado capaz de resolver problemáticas y generar ahorros económicos para la empresa.
    </p>

    <br />

    {/* <EnrollmentForm
      title='¡Separa tu lugar!'
      formName='newsletter'
      text='Sé el primero en enterarte de nuevos cursos y eventos.'
    /> */}
    <style jsx>{`
      b {
        margin-right: 5px;
      }
    `}</style>
  </Layout>
);

export default withTranslations()(JavaFxCourse);
