import React from 'react';

export default ({ children }) => (
  <div className='container'>
    <span className='text'>{children}</span>
    <div className='bar' />
    <style jsx>{`
      .text {
        font-size: 30px;
      }

      .bar {
        height: 4px;
        width: 45px;
        margin-top: 6px;
        background-color: #ffa500;
      }

      .container {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    `}</style>
  </div>
);
